<template>
  <div class="shoping-cart">
    <div class="w ww gwc_tab">
      <div class="gwc_tab_t">
        <ul class="zx_t">
          <li class="active">
            {{ this.$t("AllGoods")
            }}<span>（{{ proList ? proList.length : 0 }}）</span><i></i>
          </li>
          <!-- <li>降价商品<span>（3）</span></li> -->
        </ul>
        <div class="gwc_tab_t_1">
          {{ this.$t("SelectedGoods") }}￥{{ price.toFixed(2)
          }}<a @click="goPay">{{ this.$t("settlement") }}</a>
        </div>
        <div class="clear"></div>
      </div>
      <ul class="zx_c">
        <li class="thisclass">
          <div class="gwc_tab_c">
            <table width="100%">
              <tr>
                <th>
                  <label class="xuan">
                    <input
                      class="demo--radio"
                      type="checkbox"
                      name="demo-radio"
                      v-model="isAllcheck"
                      @click="selectedAll"
                    />
                    <span class="demo--radioInput"></span>
                  </label>
                </th>
                <th>{{ this.$t("allSelect") }}</th>
                <th>{{ this.$t("productInfo") }}</th>
                <th>{{ this.$t("attribute") }}</th>
                <th>{{ this.$t("price") }}</th>
                <th>{{ this.$t("quantity") }}</th>
                <th>{{ this.$t("total") }}</th>
                <th>{{ this.$t("operation") }}</th>
              </tr>
              <tr
                v-for="(v, i) in proList"
                :key="i"
                @click.stop="godetail(v.projectRefOwid)"
              >
                <td>
                  <label class="xuan">
                    <input
                      class="demo--radio"
                      type="checkbox"
                      name="demo-radio"
                      v-model="v.checked"
                      @click="alonCheck(i)"
                    />
                    <span class="demo--radioInput"></span>
                  </label>
                </td>
                <td><img :src="pictrueUrl + v.productPic" /></td>
                <td>
                  <h3>{{ v.productName || "-" }}</h3>
                  <p>{{ v.secondName || "" }}</p>
                </td>
                <td>{{ v.categoryName }}-{{ v.specsName }}</td>
                <td>
                  <div class="gwc_tab_1">￥{{ v.itemPrice }}<span></span></div>
                </td>
                <td>
                  <div class="gw_num">
                    <em class="jian" @click="decrease(i)">-</em>
                    <input type="number" v-model="v.itemQuantity" class="num" />
                    <em class="add" @click="increase(i)">+</em>
                  </div>
                  <!-- <div class="gwc_tab_2">有货</div> -->
                </td>
                <td>￥{{ (v.itemPrice * v.itemQuantity).toFixed(2) }}</td>
                <td>
                  <div class="gwc_tab_3">
                    <a
                      @click="addCollect(v.productRefOwid)"
                      v-if="v.isCollect === 1"
                      >放入收藏夹</a
                    >
                    <a v-else>已收藏</a>
                    <a @click="deleteCart(v.owid)">删除</a>
                  </div>
                </td>
              </tr>
            </table>

            <div class="gwc_tab_js">
              <div class="fl gwc_tab_js_1">
                <label class="xuan">
                  <input
                    class="demo--radio"
                    type="checkbox"
                    name="demo-radio"
                    v-model="isAllcheck"
                    @click="selectedAll"
                  />
                  <span class="demo--radioInput"></span>
                </label>
              </div>
              <div class="fl c3">{{ this.$t("allSelect") }}</div>
              <div class="fl gwc_tab_js_2">
                <a @click="delSelect">{{ this.$t("selectDelete") }}</a
                ><a @click="moveFavorites">{{ this.$t("putFavorites") }}</a>
              </div>
              <div class="fr gwc_tab_js_3">
                <span
                  >{{ this.$t("Selected") }} <i>{{ total }}</i>
                  {{ this.$t("items") }}</span
                >
                <p>
                  {{ this.$t("total") }}：<i>￥</i><b>{{ price.toFixed(2) }}</b>
                </p>
                <a @click="goPay">{{ this.$t("settlement") }}</a>
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ShoppingCart, delProduct, addCollect } from "./model";
import { IMGURL } from "@/utils/constant";
import { showLayer } from "@/utils/com";
export default {
  data() {
    return {
      isAllcheck: false,
      pictrueUrl: "",
      proList: [], //商品列表
      totalPage: 1,
      currentPage: 1,
      selectProduct: [],
      temp: [],
      selected: [], //选中的商品
      owids: { owids: "" }, //选中商品的主键
      collectList: { collectList: "" },
      Collect: true, //记录商品是否收藏
      prolistLength: "",
    };
  },
  components: {},
  created() {
    this.pictrueUrl = IMGURL;
    this.update();
  },

  methods: {
    async update() {
      let res = await ShoppingCart();
      this.proList = res.records;
      if (res.records) {
        this.proList = res.records;
        this.proList.forEach((item) => {
          item.checked = false;
          item.totalPrice = item.itemQuantity * item.itemPrice;
        });
        this.proList.forEach((item) => {
          item.isCollect === 1 ? (this.Collect = false) : (this.collect = true);
        });
      }
    },
    goPay() {
      this.proList.forEach((item) => {
        item.checked
          ? this.$router.push("/profile/sureCart")
          : showLayer("toast", "至少选择一件商品！");
      });

      // 筛选选中的商品存入vuex
      this.selectProduct = this.proList.filter((item) => {
        return item.checked === true;
      });
      this.$store.commit("product/selectProduct", this.selectProduct);
      // window.sessionStorage.setItem('selectProduct',JSON.stringify(this.selectProduct))
    },
    deleteCart(owid) {
      //删除购物车中的商品
      delProduct({ owids: owid }).then((res) => {
        if (res === "success") {
          this.update();
          showLayer("success", "删除成功！");
        }
      });
    },
    // 数量加
    increase(i) {
      this.proList[i].itemQuantity++;
    },
    // 数量减
    decrease(i) {
      this.proList[i].itemQuantity--;
      if (this.proList[i].itemQuantity < 1) {
        this.proList[i].itemQuantity = 1;
        showLayer("toast", "不能再少了");
      }
    },
    // 全选
    selectedAll() {
      this.isAllcheck = !this.isAllcheck;
      this.proList.forEach((item) => {
        item.checked = this.isAllcheck;
      });
    },
    // 单选
    alonCheck(i) {
      this.proList[i].checked = !this.proList[i].checked;
      this.$set(this.proList, i, this.proList[i]);
      this.proList.forEach((item) => {
        item.checked === false
          ? (this.isAllcheck = false)
          : (this.isAllcheck = true);
      });
      this.proList.forEach((item) => {
        if (item.checked === false) {
          this.isAllcheck = false;
        }
      });
    },
    // 删除选中的商品
    delSelect() {
      let selectedOwids = [];
      this.selected = this.proList.filter((item) => {
        return item.checked === true;
      });
      this.selected.forEach((item) => {
        selectedOwids.push(item.owid);
      });
      this.owids.owids = selectedOwids.join(",");
      this.proList.forEach((item) => {
        if (item.checked === true || this.isAllcheck === true) {
          delProduct(this.owids).then((res) => {
            if (res === "success") {
              this.update();
              showLayer("success", "删除成功！");
            }
          });
        }else {
          showLayer("toast", "您还没选中任何商品！");
        }
      });
    },
    // 单件商品加入收藏夹
    addCollect(collectList) {
      addCollect({ collectList: collectList }).then((res) => {
        if (res === undefined) {
          this.update();
          showLayer("sucess", "收藏成功！");
          // for (let i = 0; i < this.proList.length; i++) {
          //   this.proList[index].isCollect = 0;
          // }
          // this.Collect = false;
        }
      });
    },
    // 选中的商品移动到收藏夹
    moveFavorites() {
      let collect = [];
      this.temp = this.proList.filter((item) => {
        return item.checked === true;
      });
      this.temp.forEach((item) => {
        collect.push(item.productRefOwid);
        item.isCollect = 1;
      });
      this.collectList.collectList = collect.join(",");
      addCollect(this.collectList).then((res) => {
        if (res === undefined) {
          showLayer("success", "收藏成功！");
          this.update();
        }
      });
    },
    godetail(owid) {
      // this.$router.push({ path: "/productDetails", query: { owid: owid } });
    },
  },
  computed: {
    // 选中商品的总价
    price() {
      let totalPrice = 0;
      if (this.proList) {
        this.proList.forEach((item) => {
          if (item.checked === true || this.isAllcheck === true) {
            totalPrice += item.itemPrice * item.itemQuantity;
          }
        });
      }
      return totalPrice;
    },
    // 添加商品的数量
    total() {
      let total = 0;
      if (this.proList) {
        for (let i = 0; i < this.proList.length; i++) {
          if (this.isAllcheck === true || this.proList[i].checked === true) {
            total += this.proList[i].itemQuantity;
          }
        }
      }
      return total;
    },
  },
};
</script>
